import * as React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"

const Contact = () => (
  <Layout>
    <Seo title="Contact" />
    <div className="Contact SubPage">
      <div className="Subpage__header">
        <h1>Contact</h1>
      </div>
      <div className="Contact__content">
        <div className="maxWidth">
          <h2>Get In Touch</h2>
          <p>Need to get in touch? Send us an email and we'll get back to you as soon as possible: info@nitorfitness.com</p>
          <p>If you're looking for support for our apps please include the app name you need help with.</p>
        </div>
      </div>
    </div>
  </Layout>
)

export default Contact
